/* stylelint-disable selector-class-pattern */
:root {
    [data-theme='bnc-fraude'] {
        /* ArticleDocument */
        --article-background: #dee4ff;
        /* Body */
        --article-text-color: #00324d;
    }
}

:global {
    :local(.container) {
        --color-bncfraude-em: #5e79ff;

        overflow: clip;

        .block-heading h3 {
            color: var(--color-bncfraude-em);
            font-family: 'Maax', 'SinaNova', sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 30px;
        }

        .block-text {
            p {
                em {
                    color: var(--color-bncfraude-em);
                    font-family: 'Maax', sans-serif;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 32px;
                    font-style: normal;
                }

                &:global(.intro) {
                    em {
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: 700;
                        font-style: normal;
                    }
                }

                &:global(.conclusion) {
                    margin-top: 1.7em;
                    margin-bottom: 0;

                    color: var(--color-bncfraude-em);
                    text-align: center;
                    font-family: 'Maax', sans-serif;
                    font-weight: 700;
                    font-size: 26px;
                    text-transform: uppercase;

                    em {
                        font-size: 26px;
                        font-weight: 900;
                    }
                }
            }

            a {
                font-weight: 700;
                text-underline-offset: 0.2em;
                text-decoration-thickness: 0.04em;
            }
        }

        :global(.ctaButton) {
            display: block;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            background-color: var(--article-text-color);
            color: var(--article-background);
            font-family: 'Agrandir', sans-serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;

            padding: 0.6em 0.8em 0.4em 0.8em;
            border-radius: 24px;
        }
    }
}
